import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useInView } from "react-intersection-observer"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Projects from "../../components/projects"

const Fifa = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const { fMuseumCloudinary } = useStaticQuery(graphql`
    query {
      fMuseumCloudinary: allCloudinaryMedia(
        filter: { public_id: { regex: "/rgrq/fmuseum/" } }
      ) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `)

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Layout>
      <Header />
      <div className="project">
        <div className="container-fluid subheader">
          <div className="row">
            <div className="col-lg-4">
              <h2>02. Fifa Museum</h2>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <h2>2020</h2>
            </div>
            <div className="col-lg-4">
              <h2>Identity, Typeface Design</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12 hero hero-fmuseum">
              <div className={`hero-footer ${inView}`}>
                <div className="row justify-content-between">
                  <div className="col-lg-5 d-none d-md-block">
                    <h2>
                      <Link to="/projects/fifa">Next Project(01) ↖</Link>
                    </h2>
                    <h2>
                      <Link to="/projects/sueños">Next Project(03) ↗</Link>
                    </h2>
                  </div>
                  <div className="col-md-3 d-none d-md-block">
                    <h2>
                      <Link to="/">rg-rq.com</Link>
                    </h2>
                  </div>
                  <div className="offset-md-2 col-md-2">
                    <button onClick={scrollTop}>
                      <h2>
                        Back up
                        {inView ? (
                          <img
                            className="img-fluid"
                            src="/Up.svg"
                            alt="cheveron icon"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src="/Up-white.svg"
                            alt="cheveron icon"
                          />
                        )}
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid content">
          <div className="row text-section">
            <div className="col-lg-5">
              <p>FIFA MUSEUM </p>
            </div>
            <div className="col-lg-7">
              <p>
                Fifa Museum is focused on supporting representation and its
                community. Many of their programs represent this including an
                exhibition dedicated to the US Women’s National team in
                celebration of their accomplishments. The museum also provides a
                space for education and play.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[1].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607365238/rgrq/static/fmuseum/02_f5zcpn.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[2].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[3].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[4].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[5].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607365253/rgrq/static/fmuseum/08_dlyadv.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-12">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607365253/rgrq/static/fmuseum/09_ugwmpc.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[6].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[7].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[8].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[9].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[10].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[11].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[12].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[13].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[14].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[15].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[16].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[17].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[18].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[19].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[20].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[21].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fMuseumCloudinary.edges[22].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607365252/rgrq/static/fmuseum/27_j2a2vj.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Special Thank You</p>
            </div>
            <div className="col-lg-7">
              <p>Brad Bartlett (Professor)</p>
              <p>Sharon Park (TA)</p>
              <p>Chris Taylor (TA)</p>
              <p>Cristopher Gonzalez</p>
              <p>Mazzie Miles (Professor)</p>
              <p>Ivan Cruz (Professor)</p>
              <p>Mario Ascencio</p>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref}></div>
      <Projects />
    </Layout>
  )
}
export default Fifa
